import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { setCookie } from "helpers";
import AppBar from "components/AppBar";
import EntryList from "./AboutVehicleEntries/Sections/EntryList";
import AboutVehicle from "sections/aboutVehicleEntries/aboutVisitor";
import { Skeleton } from "antd";

class AboutVisitorEntries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
    };
  }


  componentWillUnmount() {
    setCookie("usePrevStateValues", "true");
    // setCookie("vehicle_entries", "true");
  }

  render() {
    const { phone } = this.props.match.params;

    return (
      <React.Fragment>
        <AppBar />
        {!phone ? (
          <Skeleton
            style={{ width: "100%", padding: "10px 50px" }}
            active={true}
            title={false}
            paragraph={{
              rows: 10,
            }}
          />
        ) : (
          <>
            <AboutVehicle phone={phone} params={this.props.location.state} />
            <EntryList
              phone={phone}
              params={this.props.location.state}
            />
          </>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(AboutVisitorEntries);
